<template>
  <div>
    <div class="hero-main diamond-layout white-sec" :style="style">
      <div class="container">
        <div class="row align-items-center">
          <div class="col-sm-12 col-md-6">
            <h1>AK-LABS iNKiES ALT POT-FOLIO</h1>
            <p class="lead"><span>Discover the power of crypto solid projects with AK-LABS. Our treasury focuses on investing in resilient and promising crypto projects, offering investors a curated selection of digital assets with high growth potential.</span></p>
            <div class="hero-btns">
              <a href="#distribution" class="btn el-btn">Explore</a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- eslint-disable -->
    <div id="distribution" class="token-sale c-l">
      <div class="container mt-6">
        <div class="text-center"><h2 class="section-heading">POT-FOLIO <span>Allocation</span></h2></div>
        <div class="text-center"><h2 class="section-heading">Value: <span>~{{ getCoinsSum }}$</span></h2></div>
        <div v-if="getShare > 0" class="text-center"><h2 class="section-heading">Your share: <span>~{{ getShare }}$</span></h2></div>
        <div v-show="false" class="sub-txt text-center">
          <p>Our treasury allocation is presented below.</p>
        </div>
        <div v-show="false" class="row">
          <div class="col-md-12">
            <h3><span class="strong">Why become a holder?</span></h3>
            <p>Elros is built to be flexible, to maximize revenue generating opportunities for the project. At the core of this flexibility lies the Incubator.</p>
            <p>By holding Elros, you automatically become a beneficiary of the growing Treasury Vault. The Elros team's stance on the current bear market context is that it is both <span class="strong">temporary</span>, and a lucrative <span class="strong">opportunity to accumulate high-cap cryptocurrency assets.</span></p>
          </div>
        </div>
        <div v-show="false" class="row">
          <div class="col-md-12">
            <h3><span class="strong">Strategy</span></h3>
            <p>Considering these two basic premises, our goal is to use funds generated from events or services rendered to:
              <ul>
                <li><span class="strong">DCA</span> into <span class="strong">blue-chip cryptocurrencies</span> such as BTC, ETH, SOL or BNB. We believe such assets are low-risk   and guaranteed to generate profit once the next bull market starts.</li>
                <li>Buy   into <span class="strong">long-term  DeFi projects</span>, hand-picked with care.</li>
              </ul>
            </p>
            <p>Together, the Elros team has many <span class="strong">years of cumulative trading experience</span> in the crypto market. All team members are well-versed in risk management strategies. They will lead Elros’ strategy as to what assets to acquire and when is the best time to DCA.</p>
          </div>
        </div>
        <div v-show="false" class="row">
          <div class="col-md-12">
            <h3><span class="strong">Community involvement</span></h3>
            <p>Elros holders will also be able to <span class="strong">vote</span> on the assets which will be added to the treasury in some cases. So far, this has been done in the form of anonymous polls, however these are not adequate for a larger project and a <span class="strong">fairer and more transparent system</span>, that considers the number of Elros tokens a voter owns, is <span class="strong">under development.</span></p>
          </div>
        </div>
        <div v-show="false" class="row">
          <div class="col-md-12">
            <h3><span class="strong">Transparency</span></h3>
            <p>Measures have been taken to ensure the <span class="strong">transparency</span> of the project, as well as the security of the <span class="strong">Treasury Vault</span>. The addresses of the treasury wallets are publicly visible, and all transactions will be shared on the project’s news channels.</p>
          </div>
        </div>
        <div v-show="false" class="row">
          <div class="col-md-12">
            <h3><span class="strong">Wallet Addresses</span></h3>
            <div class="token-allocation-box row">
              <div class="wallet-container col-md-12">
                <h4>BSC/ETH/CRO</h4>
                <div class="wallet">
                  <a href="https://bscscan.com/address/0x54EFD1f059f520a83615C74486aa7d790f0521AB" target="_blank"><span>{{ bnbwa }}</span></a>
                  <a href="" @click.prevent="onCopy(bnbwa)"><i class="fa fa-copy" /></a>
                </div>
              </div>
              <div class="wallet-container col-md-12">
                <h4>Solana</h4>
                <div class="wallet">
                  <a href="https://explorer.solana.com/address/9wzzX3VdSrcmJiUHaCjBs4evZko2jQqp2wjNgT7RQbUJ/tokens" target="_blank"><span>{{ solwa }}</span></a>
                  <a href="" @click.prevent="onCopy(solwa)"><i class="fa fa-copy" /></a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <h4>Token Allocation</h4>
            <table>
              <tr>
                <th>Name</th>
                <th>Symbol</th>
                <th>Holdings</th>
              </tr>
              <tr v-for="(item, i) in treasury" :key="i">
                <td>
                  <a :href="item.link" target="_blank">
                    <img class="coin-logo" :src="item.img" :alt="`${item.name} logo`" />
                    <span class="item-name">{{ item.name }}</span>
                  </a>
                </td>
                <td>{{ item.symbol }}</td>
                <td>{{ item.holdings }}</td>
              </tr>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import Vue from 'vue';
  import { mapActions, mapGetters } from 'vuex';
  import { nanoid } from 'nanoid';
  import { copyToClipboard } from '@/utils/copy-to-clipboard';
  import diamondAnimationMobile from '@/assets/png/diamond-animation-mobile.png';
  import inkie from '@/assets/gif/inkie-big.gif';
  import backgroundBanner from '@/assets/jpg/treasurybg.jpg';

  export default Vue.extend({
    name: 'pot-folio',

    data: () => ({
      coins: [],
      diamondAnimationMobile,
      inkie,
      bnbwa: '0xd324Fb034e579B0Ec01b61052239FcBbD11B993B',
      solwa: 'JDMWQAMC4tbcgropU6q8XpjDVAoS3fJ5yGsV7ZyXH4aA',
      style: { backgroundImage: `url(${backgroundBanner})` },
      treasury: [
        {
          img: 'https://s2.coinmarketcap.com/static/img/coins/64x64/30859.png',
          link: 'https://coinmarketcap.com/currencies/hoppy-coin/',
          name: 'Hoppy',
          symbol: 'HOPPY',
          holdings: '17,396,734',
        },
        {
          img: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9481.png',
          link: 'https://coinmarketcap.com/currencies/pendle/',
          name: 'Pendle',
          symbol: 'PENDLE',
          holdings: '715',
        },
        {
          img: 'https://s2.coinmarketcap.com/static/img/coins/64x64/21159.png',
          link: 'https://coinmarketcap.com/currencies/ondo-finance/',
          name: 'Ondo',
          symbol: 'ONDO',
          holdings: '4,422',
        },
        {
          img: 'https://s2.coinmarketcap.com/static/img/coins/64x64/24911.png',
          link: 'https://coinmarketcap.com/currencies/turbo/',
          name: 'Turbo',
          symbol: 'TURBO',
          holdings: '500,000',
        },
        {
          img: 'https://s2.coinmarketcap.com/static/img/coins/64x64/30083.png',
          link: 'https://coinmarketcap.com/currencies/aethir/',
          name: 'Aethir',
          symbol: 'ATH',
          holdings: '58,002',
        },
        {
          img: 'https://s2.coinmarketcap.com/static/img/coins/64x64/29835.png',
          link: 'https://coinmarketcap.com/currencies/io-net/',
          name: 'io.net',
          symbol: 'IO',
          holdings: '1,054',
        },
        {
          img: 'https://s2.coinmarketcap.com/static/img/coins/64x64/2545.png',
          link: 'https://coinmarketcap.com/currencies/arcblock/',
          name: 'Arcblock',
          symbol: 'ABT',
          holdings: '1,962',
        },
        {
          img: 'https://s2.coinmarketcap.com/static/img/coins/64x64/13967.png',
          link: 'https://coinmarketcap.com/currencies/goldfinch-protocol/',
          name: 'Goldfinch',
          symbol: 'GFI',
          holdings: '2,018',
        },
        {
          img: 'https://s2.coinmarketcap.com/static/img/coins/64x64/7226.png',
          link: 'https://coinmarketcap.com/currencies/injective/',
          name: 'Injective',
          symbol: 'INJ',
          holdings: '239',
        },
        {
          img: 'https://s2.coinmarketcap.com/static/img/coins/64x64/5690.png',
          link: 'https://coinmarketcap.com/currencies/render/',
          name: 'Render',
          symbol: 'RNDR',
          holdings: '947',
        },
        {
          img: 'https://s2.coinmarketcap.com/static/img/coins/64x64/19055.png',
          link: 'https://coinmarketcap.com/currencies/solidus-ai-tech/',
          name: 'Solidus Ai Tech',
          symbol: 'AITECH',
          holdings: '42,991',
        },
        {
          img: 'https://s2.coinmarketcap.com/static/img/coins/64x64/24007.png',
          link: 'https://coinmarketcap.com/currencies/delysium/',
          name: 'Delysium ',
          symbol: 'AGI',
          holdings: '42,775',
        },
      ],
    }),
    computed: {
      ...mapGetters({
        getCoinsList: 'common/getCoinsList',
        getCoins: 'common/getCoins',
        getHoldings: 'session/getHoldings',
      }),
      getCoinsSum() {
        let sum = 0;

        this.getCoinsList.forEach((coin) => {
          const selectedCoin = this.getCoins.find(item => item.symbol === coin.symbol);
          const total = selectedCoin ? selectedCoin.value * coin.usd_price : 0;

          sum += total;
        });
        return sum.toFixed(2);
      },
      getShare() {
        const share = this.getHoldings;

        let value = 0;
        let percentage = 0;

        if (share) {
          percentage = ((this.getHoldings * 100) / 279) / 100;
          value = this.getCoinsSum * percentage;
        }

        return value.toFixed(2);
      },
    },
    mounted() {
      window.initTreasuryAnimation();
      window.initTreasury();
    },
    created() {
      this.fetchCoinsList();
    },
    methods: {
      ...mapActions({
        fetchCoinsList: 'common/fetchCoinsList',
      }),
      onCopy(txt) {
        copyToClipboard(txt);
        this.$toasts.toast({
          id: nanoid(),
          message: 'The link has been copied to clipboard',
          title: 'Success',
          intent: 'success',
        });
      },
    },
  });
</script>
<style>
.wallet-container {
  display: flex;
  flex-direction: column;
  margin: 15px 0;
  padding: 0 10px;
}

.wallet-container h4 {
  font-size: 18px;
  text-transform: uppercase;
}

.wallet-container .wallet {
  font-size: 15px;
  line-height: 1.8;
  text-transform: uppercase;
}

.wallet-container .wallet span {
  color: #ea721c;
  display: block;
  font-weight: bold;
}

@media only screen and (max-width: 1199px) {
  .wallet-container .wallet {
    font-size: 12px;
  }

  .wallet-container .egld-wallet {
    font-size: 8px;
  }
}

table {
  border-collapse: collapse;
  font-family: arial, sans-serif;
  width: 100%;
}

td,
th {
  border: 1px solid #dddddd;
  padding: 8px;
  text-align: left;
}

tr:nth-child(even) {
  background-color: #dddddd;
}

td img {
  border-radius: 12px;
  height: 24px;
  margin-right: 8px;
  width: 24px;
}

.item-name {
  color: #0078c4;
  font-weight: bold;
}

</style>
